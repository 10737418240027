<template>
  <div
    ref="moduleElement"
    class="module"
    :data-in-view="isModuleInView"
    :data-intersect-scroll-direction="intersectScrollDirection"
    :style="{ '--bg': hlp_background_color }"
  >
    <ol class="grid">
      <li v-for="(item, index) in items" :key="index" class="grid-item">
        <div class="grid-item-inner">
          <HlpGridCard :item="item" :index="index" />
        </div>
      </li>
    </ol>
  </div>
  <div v-if="false"><!-- prevent props from being passed to the root element --></div>
</template>

<script setup lang="ts">
defineProps<{
  // eslint-disable-next-line vue/prop-name-casing
  hlp_background_color?: string;
  items: any;
}>();

const moduleElement = ref<HTMLElement | null>(null);
const isModuleInView = ref(false);
const intersectScrollDirection = ref<string | null>(null);
let intersectionObserver: IntersectionObserver | null = null;

onMounted(() => {
  intersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      isModuleInView.value = entry.isIntersecting;
      if (entry.isIntersecting) {
        intersectScrollDirection.value = entry.boundingClientRect.y - entry.intersectionRect.y < 0 ? 'up' : 'down';
      }
    });
  });

  if (moduleElement.value) {
    intersectionObserver.observe(moduleElement.value);
  }
});

onUnmounted(() => {
  // Clean up the observer when the component is unmounted
  if (intersectionObserver) {
    intersectionObserver.disconnect();
    intersectionObserver = null;
  }
});
</script>

<style lang="scss" scoped>
.module {
  background-color: var(--bg, #1e1e1e);
  color: #fff;
  padding: 2rem 1rem 5rem;

  @include local-mixins.desktop_and_tablet {
    padding: 2rem 1rem 8rem;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 5rem;

  @include local-mixins.desktop_and_tablet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    max-width: calc(local-vars.$xxl-content-max-width - 4rem);
    margin: auto;
    padding-block: 3.5rem;
    gap: 0 2rem;
  }
}

.grid-item {
  position: relative;
  display: flex;
  justify-content: center;
  transition: top 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  top: 140px;
}

.grid-item:nth-child(2) {
  transition-duration: 2s;
}

.module[data-in-view='true'] {
  .grid-item {
    top: 0;
  }
}

// @note: we don't want the animation on scroll up
.module[data-in-view='true'][data-intersect-scroll-direction='up'] {
  .grid-item {
    transition-duration: 0s;
  }
}

.grid-item-inner {
  width: 100%;
}

@include local-mixins.desktop_and_tablet {
  .grid-item:nth-child(1) {
    grid-column: 2;
    grid-row: 1;
  }

  .grid-item:nth-child(2) {
    padding-top: 145px;
    grid-column: 1;
    grid-row: 1;
    grid-row-start: 1;
    grid-row-end: 3;
    justify-self: flex-start;
  }

  .grid-item:nth-child(3) {
    padding-top: 145px;
    grid-column: 1;
    grid-row: 3;
    display: flex;
    justify-self: flex-end;
  }

  .grid-item:nth-child(4) {
    padding-top: 145px;
    grid-column: 2;
    grid-row: 2;
    grid-row-start: 2;
    grid-row-end: 4;
    display: flex;
    justify-content: center;
  }
}
</style>
